import Vuex from 'vuex'
import record from "@/store/modules/record";
import axios from 'axios'

const store = new Vuex.Store({
    state: {
        error: null,
        loadCounter: 0
    },

    mutations: {
        addLoader(state) {
            state.loadCounter++
        },

        removeLoader(state) {
            state.loadCounter--
        },

        setError(state, payload) {
            state.error = payload
        },

        resetError(state) {
            state.error = null
        },
    },

    actions: {},

    getters: {
        isLoading(state) {
            return state.loadCounter > 0
        }
    },

    modules: {
        record,
    }
});

axios.interceptors.request.use(
    function(config) {
        store.commit('addLoader')
        return config
    },
    function(error) {
        store.commit('removeLoader')
        store.commit('setError', 'Ошибка подключения. Попробуйте перезагрузить страницу.')
        return Promise.reject(error)
    }
)

export default store
