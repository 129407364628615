import { createApp } from 'vue'
import App from './App.vue'
import { plugin, defaultConfig } from '@formkit/vue'
import router from "@/router";
import { SmartCaptchaPlugin } from 'vue3-smart-captcha'
import vSelect from 'vue-select'
import store from "@/store";
import vDatePicker from '@vuepic/vue-datepicker';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-select/dist/vue-select.css';
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App);

app.use(plugin, defaultConfig({
    theme: 'genesis'
}));
app.use(router);
app.use(SmartCaptchaPlugin);
app.use(store);
app.mount('#app');
app.component('v-select', vSelect);
app.component('v-date-picker', vDatePicker);

import 'bootstrap/dist/js/bootstrap.js';
