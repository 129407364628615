import { createRouter, createWebHistory } from 'vue-router'
import { isBefore } from 'date-fns';
import { useStore } from "vuex";

const router = createRouter({
    routes: [
        {
            name: 'FormRecord',
            path: '/',
            beforeEnter: (to, from, next) => {
                if (localStorage.getItem('record')) {
                    const record = JSON.parse(localStorage.getItem('record'));

                    if (record.type === 'success' && isBefore(new Date(), record.date)) {
                        const store = useStore();
                        console.log(record);
                        store.commit('setFormData', {
                            'name': record.name,
                            'phone': record.phone,
                            'branchName': record.branchName,
                            'date': record.date,
                            'timeStart': record.time
                        });
                        next('/success');
                    } else {
                        localStorage.removeItem('record');
                    }
                }
                next();
            },
            component: () => import("@/components/Records/FormRecord")
        },
        {
            name: 'FormRecordConfirm',
            path: '/confirm',
            component: () => import("@/components/Records/FormRecordConfirm")
        },
        {
            name: 'FormTimeRecord',
            path: '/time',
            component: () => import('@/components/Records/FormTimeRecord')
        },
        {
            name: 'RecordSuccess',
            path: '/success',
            component: () => import('@/components/Records/RecordSuccess')
        },
        {
            name: 'RecordExist',
            path: '/exist',
            component: () => import('@/components/Records/RecordExist')
        },
        {
            name: 'Personal',
            path: '/personal',
            component: () => import('@/components/Records/PersonalLink')
        },
        {
            name: 'RecordCancelled',
            path: '/cancelled',
            component: () => import('@/components/Records/RecordCancelled')
        }
    ],
    history: createWebHistory()
})

export default router;
