import axios from "axios";
import config from "@/../config";
import { mapState } from "vuex";

export default {
    state: () => ({
        times: [],
        branches: [],
        branch_selected: undefined,
        contact_hash: '',
        active_cons: undefined,
        personal: undefined,
        link: '',
        phone: '',
        name: '',
        branchName: '',
        date: '',
        time: '',
        office_id: '',
        consultations_date_limit: 5,
        timeStart: '',
        timeEnd: ''
    }),

    computed: mapState([
        'times',
        'branches',
        'branch_selected',
        'contact_hash',
        'active_cons',
        'personal',
        'link',
        'phone',
        'name',
        'branchName',
        'date',
        'time',
        'office_id',
        'consultations_date_limit',
        'timeStart',
        'timeEnd'
    ]),

    mutations: {
        clearTimeList() {
            this.times = [];
        },
        setTimeList(state, times) {
            this.state.times = times;
        },
        clearCityList() {
            this.state.cities = [];
        },
        setContactHash(state, value) {
            this.state.contact_hash = value;
        },
        setActiveCons(state, data) {
            this.state.active_cons = data;
        },
        clearActiveCons() {
            this.state.active_cons = undefined;
        },
        setPersonal(state, data) {
            this.state.personal = data;
        },
        setBranch(state, branch) {
            this.state.branch_selected = branch;
        },
        setPhone(state, phone) {
            this.state.phone = phone;
        },
        setName(state, name) {
            this.state.name = name;
        },
        setLink(state, link) {
            this.state.link = link;
        },
        setFormData(state, data) {
            this.state.name = data.name ?? this.state.name;
            this.state.phone = data.phone ?? this.state.phone;
            this.state.branchName = data.branchName ?? this.state.branchName;
            this.state.office_id = data.office_id ?? this.state.office_id;
            this.state.date = data.date ?? this.state.date;
            this.state.consultations_date_limit = data.consultations_date_limit ?? this.state.consultations_date_limit;
            this.state.timeStart = data.timeStart ?? this.state.timeStart;
            this.state.timeEnd = data.timeEnd ?? this.state.timeEnd;
            this.state.link = data.link ?? this.state.link;
        }
    },
    actions: {
        async recoverState({ commit, dispatch }, contact_hash) {
            const requestConfig = {
                method: "get",
                url: `${config.apiUrl}/state?contact_hash=${contact_hash}`,
                headers: {
                    "Authorization": `Bearer ${config.apiToken}`
                },
            };
            let state = await axios(requestConfig).then((response) => ({data: response.data.data, status: response.status}));
            state.data.date = state.data.consultation_date;
            state.data.branchName = await dispatch('fetchOfficeName', state.data.office_id);
            commit('setFormData', state.data);
        },

        async setConsData({ commit, dispatch }, data) {
            commit('setActiveCons', {
                'cityId': data.cityId,
                'officeId': data.officeId,
                'branchName': await dispatch('fetchOfficeName', data.officeId),
                'dateLocal': data.dateLocal,
                'beginTimeLocal': data.beginTimeLocal,
                'endTimeLocal': data.endTimeLocal,
                'timeStart': data.beginTimeLocal,
                'duration': data.duration,
                'lastDate': data.beginsAtUtc
            });
        },

        setPersonalData({ commit }, data) {
            commit('setPersonal', {
                'x_user_id': data.xUserId ?? '',
                'phone': data.phone,
                'utm_campaign': data.utm.utm_campaign,
                'utm_content': data.utm.utm_content,
                'utm_source': data.utm.utm_source
            });
        },

        async fetchOfficeName({ commit, dispatch }, officeId) {
            const branches = await dispatch('fetchOffices');
            let name = '';
            branches.data.forEach(function (item) {
                if (officeId === item.office_id) {
                    name = item.address_sms || item.address;
                    commit('setBranch', item);
                }
            })
            return name;
        },

        formRecord({ commit }, payload) {
            let data = {
                name: payload.name,
                phone: payload.phone,
                city_id: payload.city_id,
                office_id: payload.office_id,
                date: payload.date,
                slot: payload.slot,
                smart_token: payload.smart_token,
                utm_source: payload.utm_source,
                utm_medium: payload.utm_medium,
                utm_campaign: payload.utm_campaign,
                utm_content: payload.utm_content,
                utm_term: payload.utm_term,
                attributio: payload.attributio,
                x_user_id: payload.x_user_id,
                agent_ref: payload.agent_ref
            };
            const requestConfig = {
                method: "post",
                url: config.apiUrl + "/form",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${config.apiToken}`
                },
                data: JSON.stringify(data)
            };
            commit('clearTimeList')
            return axios(requestConfig).then((response) => ({data: response.data, status: response.status}));
        },

        confirmRecord({ commit }, payload) {
            let data = {
                phone: payload.phone,
                code: payload.code,
                is_editing: payload.is_editing
            };
            const requestConfig = {
                method: "post",
                url: config.apiUrl + "/confirm",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${config.apiToken}`
                },
                data: JSON.stringify(data),
            };
            commit('clearTimeList');
            return axios(requestConfig).then((response) => ({data: response.data, status: response.status})).catch((error) => ({data: error.response.data, status: error.response.status}));
        },

        takeRecordTimeSlot({ commit }, payload) {
            let data = {
                slot: payload.slot,
                phone: payload.phone,
                date: payload.date,
                is_editing: payload.is_editing
            };
            const requestConfig = {
                method: "post",
                url: config.apiUrl + "/form/slot",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${config.apiToken}`
                },
                data: JSON.stringify(data),
            };
            commit('clearTimeList');
            return axios(requestConfig).then((response) => ({data: response.data, status: response.status}));
        },

        fetchTimeSlots({ commit }, payload) {
            const requestConfig = {
                method: "get",
                url: `${config.apiUrl}/slot?office_id=${payload.office_id}&date=${payload.date}`,
                headers: {
                    "Authorization": `Bearer ${config.apiToken}`
                },
            };
            commit('clearTimeList');
            return axios(requestConfig).then((response) => ({data: response.data.data, status: response.status}));
        },

        fetchOffices({ commit }) {
            const requestConfig = {
                method: "get",
                url: `${config.apiUrl}/office`,
                headers: {
                    "Authorization": `Bearer ${config.apiToken}`
                },
            };
            commit('clearCityList');
            return axios(requestConfig).then((response) => ({data: response.data, status: response.status}));
        },

        getNewCode({ commit }, { phone }) {
            const requestConfig = {
                method: "post",
                url: `${config.apiUrl}/code`,
                data: { phone },
                headers: {
                    "Authorization": `Bearer ${config.apiToken}`
                },
            };
            commit('clearCityList');
            return axios(requestConfig).then((response) => ({data: response.data, status: response.status}));
        },

        setFormatDate({ commit }, { date }) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            commit('clearTimeList');
            return [
                year, 
                month.length < 2 ? month = '0' + month : month,
                day.length < 2 ? day = '0' + day : day
            ].join('-');
        },

        // eslint-disable-next-line no-unused-vars
        processPersonalLink({ commit }, query) {
            const requestConfig = {
                method: "get",
                url: `${config.apiUrl}/personal?${query}`,
                headers: {
                    "Authorization": `Bearer ${config.apiToken}`
                },
            };
            return axios(requestConfig).then((response) => ({data: response.data, status: response.status})).catch(() => ({status: 422}));
        },
        
        // eslint-disable-next-line no-unused-vars
        cancelRecord({ commit }) {
            const requestConfig = {
                method: "post",
                url: `${config.apiUrl}/cancel`,
                data: { 'contact_hash': sessionStorage.contact_hash },
                headers: {
                    "Authorization": `Bearer ${config.apiToken}`
                },
            };
            return axios(requestConfig).then((response) => ({status: response.status}));
        }
    },
    getters: {

    }
}
